import { Grid, Slide } from "@mui/material";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import ProfileCard from "../ProfileCard";
import Calendario from "components/Calendario";
import EventosCard from "components/Calendario/Eventos";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { gql, useLazyQuery } from "@apollo/client";
import { getUproColor } from "utils/colors";
import ModalExamen from "../ModalExamen";

export default function Resumen({ usuario, materia, loading, refetch, setTab }) {
  const [selectedFecha, setSelectedFecha] = useState(null);
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [openExamenModal, setOpenExamenModal] = useState(false);
  const [selectedMes, setSelectedMes] = useState(dayjs().month());
  const [eventos, setEventos] = useState([]);
  const [examenes, setExamenes] = useState([]);

  const [getExamenes, { refetch: refetchExamenes }] = useLazyQuery(gql`
    query getExamenes($filter: JSON, $fechaInicio: String, $fechaFin: String) {
      examenes(filter: $filter, fechaInicio: $fechaInicio, fechaFin: $fechaFin) {
        id
        titulo
        fecha
      }
    }
  `);

  useEffect(() => {
    if (usuario && materia && selectedMes) {
      getExamenes({
        variables: {
          filter: {
            idMateria: materia.id,
          },
          fechaInicio: dayjs().month(selectedMes).startOf("month").toISOString(),
          fechaFin: dayjs().month(selectedMes).endOf("month").toISOString(),
        },
      }).then((res) => {
        setExamenes(res.data.examenes);
      });
    }
  }, [usuario, materia, getExamenes, selectedMes]);

  useEffect(() => {
    let eventos = [];
    if (examenes) {
      examenes.forEach((examen) => {
        eventos.push({
          date: examen.fecha,
          title: examen.titulo,
          type: "Examen",
          icon: "quizz",
          color: getUproColor("uproPink"),
          action: () => {
            setSelectedExamen(examen);
            setOpenExamenModal(true);
          },
        });
      });
    }
    setEventos(eventos);
  }, [examenes]);

  return (
    <SoftBox
      gap={2}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      <Slide direction="right" in={selectedFecha !== null} mountOnEnter unmountOnExit>
        <SoftBox
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          <EventosCard
            fecha={selectedFecha}
            eventos={
              eventos.filter((evento) => dayjs(evento.date).isSame(selectedFecha, "day")) || []
            }
            loading={loading}
          />
        </SoftBox>
      </Slide>
      <Slide direction="up" in={selectedFecha !== null} mountOnEnter unmountOnExit>
        <SoftBox
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
            display: {
              xs: "flex",
              sm: "none",
            },
          }}
        >
          <EventosCard
            fecha={selectedFecha}
            eventos={
              eventos.filter((evento) => dayjs(evento.date).isSame(selectedFecha, "day")) || []
            }
            loading={loading}
          />
        </SoftBox>
      </Slide>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <SoftBox
          sx={{
            width: "100%",
            display: "flex",
            transition: "all 0.5s",
          }}
        >
          <Calendario
            events={eventos}
            onDateSelect={(date) => {
              setSelectedFecha(date);
            }}
            onMonthChange={(month) => {
              setSelectedMes(month);
            }}
          />
        </SoftBox>
      </Slide>
      <ModalExamen
        open={openExamenModal}
        handleClose={() => setOpenExamenModal(false)}
        refetch={refetchExamenes}
        selectedExamen={selectedExamen}
      />
    </SoftBox>
  );
}

Resumen.propTypes = {
  usuario: PropTypes.object,
  materia: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  setTab: PropTypes.func,
};
