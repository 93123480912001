// React
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

// Components
import {
  Card,
  CircularProgress,
  Collapse,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ModalDelete from "components/Modals/Delete";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import * as XLSX from "xlsx";
import ModalAlumno from "./components/ModalAlumno";
import ModalComentarios from "./components/ModalComentarios";
import ModalDocumentacionAlumno from "./components/ModalDocumentacion";
import ModalInscripciones from "./components/ModalInscripciones";
import ModalReporteInscripciones from "./components/ModalReporteInscripciones";
import ModalSocioambiental from "./components/ModalSocioambiental";
import dataAlumnos from "./data/dataAlumnos";
import ModalEstadoInscripciones from "./components/ModalEstadoInscripciones";

function Alumnos() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
  const [openModalSocioambiental, setOpenModalSocioambiental] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalReportesPreinscripciones, setOpenModalReportesPreinscripciones] = useState(false);
  const [tipoReporte, setTipoReporte] = useState();
  const [openModalInscripciones, setOpenModalInscripciones] = useState(false);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);
  const [expandReportes, setExpandReportes] = useState(false);
  const [openModalEstadoInscripciones, setOpenModalEstadoInscripciones] = useState(false);

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataAlumnos;
  const { menu } = useContext(MenuContext);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedUsuario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteUser] = useMutation(
    gql`
      mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getUsuarios($search: String, $page: Int, $order: Order, $filter: JSON) {
        paginationInfo {
          pages
          total
        }
        usuarios(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          nombre
          tipoUser
          idsPermisos
          estadoInscripcionCurso
          apellido
          username
          email
          tipoUser
          nroLegajo
          dni
          genero
          esDiscapacitado
          discapacidad
          sedeCurso {
            id
            nombre
          }
          sedeCarrera {
            id
            nombre
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        filter: {
          ...filter,
          tipoUser: "Alumno",
        },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getAlumnosReporte, { loading: loadingDescargar }] = useLazyQuery(
    gql`
      query getUsuarios($search: String, $order: Order, $filter: JSON) {
        usuarios(search: $search, order: $order, filter: $filter) {
          id
          nombre
          tipoUser
          estadoInscripcionCurso
          apellido
          email
          tipoUser
          dni
          genero
          telefono
          sedeCurso {
            id
            nombre
          }
        }
      }
    `
  );

  const { data: sedes } = useQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
        }
      }
    `
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      <MenuItem
        disabled={!verificarAcceso(6)}
        onClick={() => {
          setOpenModalAdd(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Editar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              edit
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          setOpenModalDocumentacion(true);
        }}
        disabled={!verificarAcceso(8)}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Documentación</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproBlue"),
              }}
            >
              description
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          setOpenModalInscripciones(true);
        }}
        disabled={!verificarAcceso(9)}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Ver inscripciones</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproYellow"),
              }}
            >
              history_edu
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalObservaciones(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Observaciones</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("dark"),
              }}
            >
              forum
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        disabled={!verificarAcceso(6)}
        onClick={() => {
          setOpenModalSocioambiental(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Formulario Socioambiental</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              checklist
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalDelete(true);
          handleCloseMenu();
        }}
        disabled={!verificarAcceso(7)}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Eliminar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color:
                  selectedUsuario?.tipoUser === "Administrador"
                    ? getUproColor()
                    : getUproColor("uproPink"),
              }}
            >
              delete
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser({ variables: { id } });
      refetch();
      handleSnackbar("Alumno eliminado correctamente", "success");
    } catch (e) {
      handleSnackbar(e.message || "Error al eliminar el alumno", "error");
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.usuarios, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Alumnos</SoftTypography>
                    <SoftBox display="flex" justifyContent="end">
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                        mr={1}
                      >
                        <Tooltip title="Agregar nuevo alumno" placement="top">
                          <SoftButton
                            color="primary"
                            circular
                            iconOnly
                            disabled={!verificarAcceso(5)}
                            onClick={() => setOpenModalAdd(true)}
                          >
                            <Icon>add</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip
                          title={expandedFilter ? "Quitar filtros" : "Mostrar filtros"}
                          placement="top"
                        >
                          <SoftButton
                            color="uproYellow"
                            circular
                            iconOnly
                            onClick={() => {
                              setExpandedFilter(!expandedFilter);
                              setFilter({});
                            }}
                          >
                            <Icon>{expandedFilter ? "filter_alt_off" : "filter_alt"}</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip title="Realizar una nueva preinscripción" placement="top">
                        <Link to="/preinscripcion">
                          <SoftButton color="uproYellow" circular disabled={!verificarAcceso(5)}>
                            <Icon>person_add</Icon>
                            &nbsp;Preinscripción
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip title="Reportes" placement="top">
                        <SoftButton
                          color="uproGreen"
                          circular
                          disabled={!verificarAcceso(4)}
                          onClick={(event) => {
                            setExpandReportes(true);
                            menu({
                              open: event.currentTarget,
                              align: "right",
                              options: [
                                {
                                  name: "Estado inscripciones",
                                  icon: { icon: "data_usage", color: getUproColor("uproBlue") },
                                  onClick: () => {
                                    setOpenModalEstadoInscripciones(true);
                                  },
                                },
                                {
                                  name: "Reportes de Preinscripciones a Carreras",
                                  icon: { icon: "bar_chart", color: getUproColor("uproYellow") },
                                  onClick: () => {
                                    setTipoReporte({
                                      tipo: "Preinscripto",
                                      tipoCarrera: "Carrera",
                                    });
                                    setOpenModalReportesPreinscripciones(true);
                                  },
                                },
                                {
                                  name: "Reportes de Inscripciones a Carreras",
                                  icon: { icon: "bar_chart", color: getUproColor("uproGreen") },
                                  onClick: () => {
                                    setTipoReporte({
                                      tipo: "Inscripto",
                                      tipoCarrera: "Carrera",
                                    });
                                    setOpenModalReportesPreinscripciones(true);
                                  },
                                },
                                {
                                  name: "Reportes de Inscripciones a Cursos de Ingreso",
                                  icon: { icon: "bar_chart", color: getUproColor("uproPink") },
                                  onClick: () => {
                                    setTipoReporte({
                                      tipo: "Inscripto,Libre",
                                      tipoCarrera: "Curso de Ingreso",
                                    });
                                    setOpenModalReportesPreinscripciones(true);
                                  },
                                },
                              ],
                              handleClose: () => {},
                              onClose: () => {
                                setExpandReportes(false);
                              },
                            });
                          }}
                        >
                          <Icon>auto_graph</Icon>
                          &nbsp;Reportes&nbsp;
                          <Icon
                            sx={{
                              rotate: expandReportes ? "180deg" : "0deg",
                              transition: "all 0.5s",
                            }}
                          >
                            expand_more
                          </Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip title="Agregar nuevo alumno" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          onClick={() => setOpenModalAdd(true)}
                          disabled={!verificarAcceso(5)}
                        >
                          <Icon>add</Icon>
                          &nbsp;Agregar
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox mr={2}>
                      <Tooltip title="Descargar listado de alumnos" placement="top">
                        <SoftButton
                          color="uproBlue"
                          circular
                          iconOnly
                          disabled={loadingDescargar}
                          onClick={() => {
                            getAlumnosReporte({
                              variables: {
                                search: search || null,
                                order: { field: orderBy.campo, order: orderBy.orden },
                                filter: {
                                  ...filter,
                                  tipoUser: "Alumno",
                                },
                              },
                            }).then((data) => {
                              let reporte = data?.data?.usuarios || [];

                              let reporteExportar = reporte.map((item) => {
                                return {
                                  ID: item.id,
                                  "Apellido y Nombre": makeNiceText(
                                    `${item.apellido} ${item.nombre}`
                                  ),
                                  "Estado de inscripción al curso de ingreso":
                                    item.estadoInscripcionCurso,
                                  "Sede de curso de ingreso": item.sedeCurso?.nombre,
                                  DNI: item.dni,
                                  Email: item.email,
                                  Género: item.genero,
                                  Teléfono: item.telefono,
                                };
                              });
                              const cabecera = [
                                [
                                  `Alumnos ${
                                    filter.idSedeCurso
                                      ? `(Sede: ${
                                          sedes?.sedes.find(
                                            (sede) => sede.id === filter.idSedeCurso
                                          )?.nombre
                                        })`
                                      : ""
                                  } ${
                                    filter.estadoInscripcionCurso
                                      ? `(Estado: ${filter.estadoInscripcionCurso})`
                                      : ""
                                  } - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte de alumnos - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            });
                          }}
                        >
                          {loadingDescargar ? (
                            <CircularProgress
                              size={15}
                              color="inherit"
                            />
                          ) : (
                            <Icon>download</Icon>
                          )}
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip
                        title={expandedFilter ? "Quitar filtros" : "Mostrar filtros"}
                        placement="top"
                      >
                        <SoftButton
                          color="uproYellow"
                          circular
                          iconOnly
                          onClick={() => {
                            setExpandedFilter(!expandedFilter);
                            setFilter({});
                          }}
                        >
                          <Icon>{expandedFilter ? "filter_alt_off" : "filter_alt"}</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <Collapse in={expandedFilter}>
                  <Grid container spacing={2} mt={1} alignItems="center" justifyContent="end">
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="sedeCurso">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sede de curso de ingreso
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        value={filter.idSedeCurso || -1}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            idSedeCurso: e.target.value === -1 ? undefined : e.target.value,
                          });
                          setPage(1);
                        }}
                      >
                        <MenuItem value={-1}>Todas las sedes</MenuItem>
                        {sedes?.sedes.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="estadoInscripcionCurso">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Estado de inscripción al curso de ingreso
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        value={filter.estadoInscripcionCurso || -1}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            estadoInscripcionCurso:
                              e.target.value === -1 ? undefined : e.target.value,
                          });
                          setPage(1);
                        }}
                      >
                        <MenuItem value={-1}>Todos los estados</MenuItem>
                        <MenuItem value={"Inscripto"}>Inscripto</MenuItem>
                        <MenuItem value={"Preinscripto"}>Preinscripto</MenuItem>
                        <MenuItem value={"Aprobado"}>Aprobado</MenuItem>
                        <MenuItem value={"Libre"}>Libre</MenuItem>
                        <MenuItem value={"Baja"}>Baja</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.usuarios.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
          {renderMenu}
          <ModalAlumno
            open={openModalAdd}
            handleClose={() => {
              setOpenModalAdd(false);
              setSelectedUsuario();
            }}
            refetch={refetch}
            idUsuario={selectedUsuario?.id}
            tipo="Alumno"
          />
          <ModalDelete
            open={openModalDelete}
            handleClose={() => setOpenModalDelete(false)}
            nombre={`el alumno ${selectedUsuario?.nombre} ${selectedUsuario?.apellido}`}
            functionDelete={() => {
              handleDeleteUser(selectedUsuario.id);
              setOpenModalDelete(false);
            }}
          />
          <ModalReporteInscripciones
            open={openModalReportesPreinscripciones}
            handleClose={() => setOpenModalReportesPreinscripciones(false)}
            tipo={tipoReporte?.tipo}
            tipoCarrera={tipoReporte?.tipoCarrera}
          />
          <ModalInscripciones
            open={openModalInscripciones}
            idUsuario={selectedUsuario?.id}
            handleClose={() => setOpenModalInscripciones(false)}
            openModalDocumentacion={openModalDocumentacion}
            handleOpenDocumentacion={() => setOpenModalDocumentacion(true)}
            refetch={refetch}
          />
          <ModalDocumentacionAlumno
            open={openModalDocumentacion}
            idAlumno={selectedUsuario?.id}
            handleClose={() => setOpenModalDocumentacion(false)}
          />
          <ModalComentarios
            open={openModalObservaciones}
            handleClose={() => setOpenModalObservaciones(false)}
            alumno={selectedUsuario}
            setCountComentarios={() => {}}
            readOnly={false}
          />
          <ModalSocioambiental
            open={openModalSocioambiental}
            handleClose={() => setOpenModalSocioambiental(false)}
            idUsuario={selectedUsuario?.id}
            refetch={refetch}
          />
          <ModalEstadoInscripciones
            open={openModalEstadoInscripciones}
            handleClose={() => setOpenModalEstadoInscripciones(false)}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Alumnos;
