import { Card, Icon, MenuItem, Select } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import { getUproColor } from "utils/colors";

const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const daysInMonth = (month, year) => {
  return dayjs(new Date(year, month + 1, 0)).date();
};

const Calendario = ({ events, onDateSelect, onMonthChange }) => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(null);
  const month = currentDate.month();
  const year = currentDate.year();

  const firstDayOfMonth = dayjs(new Date(year, month, 1)).day();
  const totalDays = daysInMonth(month, year);

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setCurrentDate(currentDate.month(newMonth));
    onMonthChange(newMonth);
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setCurrentDate(currentDate.year(newYear));
  };

  const handleDayClick = (date) => {
    if (selectedDate && selectedDate.isSame(date, "day")) {
      setSelectedDate(null);
      onDateSelect(null);
    } else {
      setSelectedDate(date);
      onDateSelect(dayjs(date).format("YYYY-MM-DD"));
    }
  };

  const hasEvents = (date) => {
    return events.some((event) => dayjs(event.date).isSame(date, "day"));
  };

  const renderDaysOfWeek = () => {
    return daysOfWeek.map((day) => (
      <SoftBox key={day} sx={{ width: "14.28%", textAlign: "center" }}>
        <SoftBox
          display={{
            xs: "none",
            sm: "block",
          }}
        >
          <SoftBadge color="uproYellow" badgeContent={day} />
        </SoftBox>
        <SoftBox
          display={{
            xs: "block",
            sm: "none",
          }}
        >
          <SoftBadge color="uproYellow" badgeContent={day.slice(0, 3)} />
        </SoftBox>
      </SoftBox>
    ));
  };

  const renderDates = () => {
    const dates = [];
    const prevMonthDays = daysInMonth(month - 1, year);
    const nextMonthStartDay = 1;

    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      dates.push(
        <SoftBox
          key={`prev-${i}`}
          sx={{
            width: "14.28%",
          }}
          display="flex"
          justifyContent="center"
          p={2}
        >
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
              width: "40px",
              height: "40px",
            }}
            p={2}
          >
            <SoftTypography variant="h6" fontWeight="light" color="secondary">
              {prevMonthDays - i}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }

    // Days of current month
    for (let day = 1; day <= totalDays; day++) {
      const date = dayjs(new Date(year, month, day));
      const isSelected = selectedDate && selectedDate.isSame(date, "day");
      const hasEvent = hasEvents(date);
      dates.push(
        <SoftBox
          key={day}
          sx={{
            width: "14.28%",
            cursor: "pointer",
          }}
          display="flex"
          justifyContent="center"
          onClick={() => {
            handleDayClick(date);
          }}
          p={2}
        >
          <SoftBox
            sx={{
              display: "flex",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
              width: "40px",
              height: "40px",
              backgroundColor: isSelected ? getUproColor("uproGreen") : "transparent",
            }}
            p={2}
          >
            <SoftTypography variant="h6" color={isSelected ? "white" : "text"}>
              {day}
            </SoftTypography>
            {hasEvent && (
              <SoftBox
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "100%",
                  position: "absolute",
                  zIndex: 2,
                  top: "0",
                  right: "0",
                  fontSize: "15px",
                }}
              >
                <Icon color="primary">new_releases</Icon>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
      );
    }

    // Days from next month
    const remainingDays = (7 - (dates.length % 7)) % 7; // Calculate remaining days to complete the row
    for (let i = 0; i < remainingDays; i++) {
      dates.push(
        <SoftBox
          key={`next-${i}`}
          sx={{
            width: "14.28%",
          }}
          display="flex"
          justifyContent="center"
          p={2}
        >
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
              width: "40px",
              height: "40px",
            }}
            p={2}
          >
            <SoftTypography variant="h6" fontWeight="light" color="secondary">
              {nextMonthStartDay + i}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }

    return dates;
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        transition: "all 0.5s",
      }}
    >
      <SoftBox display="flex" justifyContent="space-between" p={2}>
        <SoftBox display="flex" alignItems="center">
          <SoftBox mr={1} display="flex" alignItems="center">
            <Icon color="primary">calendar_today</Icon>
          </SoftBox>
          <SoftTypography variant="h6" fontWeight="bold">
            Calendario
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Select
            value={month}
            onChange={handleMonthChange}
            displayEmpty
            inputProps={{ "aria-label": "Mes" }}
          >
            {months.map((monthName, index) => (
              <MenuItem key={index} value={index}>
                {monthName}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={year}
            onChange={handleYearChange}
            displayEmpty
            inputProps={{ "aria-label": "Año" }}
            sx={{ ml: 2 }}
          >
            {Array.from({ length: 10 }, (_, i) => year - 5 + i).map((yearOption) => (
              <MenuItem key={yearOption} value={yearOption}>
                {yearOption}
              </MenuItem>
            ))}
          </Select>
        </SoftBox>
      </SoftBox>
      <SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexWrap="wrap">
            {renderDaysOfWeek()}
            {renderDates()}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

Calendario.defaultProps = {
  events: [],
  onDateSelect: () => {},
  onMonthChange: () => {},
};

Calendario.propTypes = {
  events: PropTypes.array,
  onDateSelect: PropTypes.func,
  onMonthChange: PropTypes.func,
};

export default Calendario;
